import React, { useRef } from 'react';
import { Grid } from '@mui/material';
import * as Styled from './landing-page.styled';
import { benefitsBlocks } from './landing-page.consts';
import { DotIcon } from './icons/dot-icon.component';
import { BenefitsBlockComponent } from './benefits-block.component';
import { usePerspective } from '../common/hooks/use-perspective.hooks';

export const BenefitsContainer = () => {
  const dotContainerRef = useRef<any>(null);
  const dotInnerRef = useRef<any>(null);
  usePerspective(dotContainerRef, dotInnerRef, 3, 2);
  return (
    <Styled.BenefitsContainer maxWidth="xl">
      <Styled.BenefitsDotContainer ref={dotContainerRef}>
        <div ref={dotInnerRef}>
          <DotIcon />
        </div>
      </Styled.BenefitsDotContainer>
      <Styled.Title variant="h4">Benefits of Using Near:</Styled.Title>
      <Grid container spacing={3} zIndex={2}>
        {benefitsBlocks.map((block, index) => (
          <BenefitsBlockComponent key={index} block={block} />
        ))}
      </Grid>
    </Styled.BenefitsContainer>
  );
};
