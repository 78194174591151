import React from 'react';
import * as Styled from './landing-page.styled';
import COWorking from './images/co-working-space 2co-working.png';
import { SPACES } from '../theme';
import { ChevronIcon } from '../common/components/icons/chevron-icon.component';
import { HalfEllipseIcon } from './icons/half-ellipse-icon.component';
import { Box, Grid, Stack } from '@mui/material';

export const SameRoomContainer = () => (
  <Styled.SameRoomContainer maxWidth="xl">
    <Styled.SameRoomHalfEllipsesContainer>
      <Styled.Figure rotate={180} position="relative">
        <HalfEllipseIcon />
      </Styled.Figure>
      <HalfEllipseIcon />
    </Styled.SameRoomHalfEllipsesContainer>
    <Grid container spacing={5}>
      <Grid 
        item 
        md={6} 
        sm={12} 
        xs={12} 
        zIndex={2} 
        order={{ md: 1, sm: 2, xs: 2 }}
        display={{ md: "block", sm: "flex", xs: "flex" }}
        justifyContent="center"
      >
        <img src={COWorking} alt="COWorking" />
      </Grid>
      <Grid item md={6} sm={12} xs={12} order={{ md: 2, sm: 1, xs: 1 }}>
        <Stack spacing={1}>
          <Box zIndex={2}>
            <Styled.Title variant="h4">Same-Room Feeling</Styled.Title>
            <Styled.SubText variant="body1" marginTop={SPACES.xxxxl}>
              The simplicity of using <Styled.TextBold>“Hey Name”</Styled.TextBold> and <Styled.TextBold>“Bye-bye”</Styled.TextBold> voice commands creates a same-room
              feeling for remote teams. This feature mimics the ease of starting and ending
              conversations in a physical space, ensuring that your team feels like they’re working
              side-by-side, even if they’re miles apart.
            </Styled.SubText>
            <Styled.SubText variant="body1" marginTop={SPACES.xl}>
              This enhanced sense of presence fosters strong working relationships and improves overall
              team morale.
            </Styled.SubText>
          </Box>
          <Box display="flex" justifyContent="flex-end" zIndex={2}>
            <Styled.NavbarButton
              target="_blank" 
              href={process.env.REACT_APP_REQUEST_ACCESS_URL} 
            >
              Request Access
              <Styled.ChevronContainer rotate={-90}>
                <ChevronIcon />
              </Styled.ChevronContainer>
            </Styled.NavbarButton>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  </Styled.SameRoomContainer>
);
