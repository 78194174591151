import { FC } from "react";

export const ChatIcon: FC = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M39.8632 9.75544V30.6039C39.8632 32.3106 38.4766 33.6973 36.7699 33.6973H27.1699C26.2778 33.6973 25.4148 34.0367 24.7554 34.6573L18.0935 40.9312C17.2111 41.7651 15.7663 41.4742 15.2814 40.3591L13.2742 35.8015C12.7117 34.5215 11.4511 33.6973 10.0548 33.6973H6.95173C5.24507 33.6973 3.8584 32.3106 3.8584 30.6039V9.74575C3.8584 8.03908 5.23537 6.66211 6.95173 6.66211H36.7699C38.4766 6.66211 39.8632 8.04878 39.8632 9.75544Z" fill="#6BB3F2"/>
    <path d="M16.8815 42.2884C16.7069 42.2884 16.5324 42.269 16.3578 42.2399C15.5239 42.0654 14.816 41.5029 14.4669 40.7175L12.4596 36.1599C12.0427 35.1999 11.0924 34.5793 10.0451 34.5793H6.94207C4.75055 34.5793 2.96631 32.7951 2.96631 30.6035V9.74536C2.96631 7.55384 4.75055 5.7793 6.94207 5.7793H36.7602C38.9518 5.7793 40.736 7.56354 40.736 9.75505V30.6035C40.736 32.7951 38.9518 34.5793 36.7602 34.5793H27.1602C26.4815 34.5793 25.8415 34.8314 25.3469 35.2969L18.6851 41.5708C18.2002 42.0363 17.5506 42.2884 16.8815 42.2884ZM6.94207 7.54415C5.72025 7.54415 4.72146 8.53324 4.72146 9.75505V30.6132C4.72146 31.8351 5.71055 32.8338 6.94207 32.8338H10.0451C11.7906 32.8338 13.3712 33.8617 14.0693 35.4617L16.0766 40.0193C16.193 40.2811 16.4257 40.4654 16.7069 40.5235C16.9881 40.5817 17.279 40.5041 17.4827 40.3005L24.1445 34.0266C24.9687 33.2508 26.0354 32.8338 27.1602 32.8338H36.7602C37.9821 32.8338 38.9809 31.8351 38.9809 30.6132V9.76475C38.9809 8.54293 37.9918 7.54415 36.7602 7.54415H6.94207Z" fill="black"/>
    <path d="M24.1346 25.689V46.5375C24.1346 48.2442 25.5213 49.6309 27.228 49.6309H36.828C37.7201 49.6309 38.5831 49.9702 39.2425 50.5909L45.9043 56.8648C46.7868 57.6987 48.2316 57.4078 48.7165 56.2927L50.7237 51.7351C51.2862 50.4551 52.5468 49.6309 53.9431 49.6309H57.0462C58.7528 49.6309 60.1395 48.2442 60.1395 46.5375V25.6793C60.1395 23.9727 58.7625 22.5957 57.0462 22.5957H27.228C25.5213 22.5957 24.1346 23.9824 24.1346 25.689Z" fill="#A0DBF2"/>
    <path d="M47.1166 58.222C46.4475 58.222 45.7978 57.9699 45.3032 57.5044L38.6414 51.2305C38.1469 50.765 37.5069 50.5129 36.8281 50.5129H27.2281C25.0366 50.5129 23.2523 48.7286 23.2523 46.5371V25.6886C23.2523 23.4971 25.0366 21.7129 27.2281 21.7129H57.0463C59.2378 21.7129 61.022 23.4971 61.022 25.679V46.5371C61.022 48.7286 59.2378 50.5129 57.0463 50.5129H53.9432C52.896 50.5129 51.9457 51.1335 51.5287 52.0935L49.5214 56.6511C49.182 57.4365 48.4741 57.999 47.6305 58.1735C47.4657 58.2026 47.2911 58.222 47.1166 58.222ZM27.2281 23.468C26.0063 23.468 25.0075 24.4668 25.0075 25.6886V46.5371C25.0075 47.759 26.0063 48.7577 27.2281 48.7577H36.8281C37.9529 48.7577 39.0196 49.1844 39.8438 49.9505L46.5057 56.2244C46.719 56.4183 47.0002 56.5056 47.2814 56.4474C47.5626 56.3893 47.7954 56.205 47.9117 55.9432L49.919 51.3856C50.6172 49.7856 52.1978 48.7577 53.9432 48.7577H57.0463C58.2681 48.7577 59.2669 47.759 59.2669 46.5371V25.679C59.2669 24.4571 58.2681 23.468 57.0463 23.468H27.2281Z" fill="black"/>
    <path d="M44.1584 31.6027H30.4857C30.0008 31.6027 29.6033 31.2052 29.6033 30.7203C29.6033 30.2355 30.0008 29.8379 30.4857 29.8379H44.1584C44.6433 29.8379 45.0408 30.2355 45.0408 30.7203C45.0408 31.2052 44.6433 31.6027 44.1584 31.6027Z" fill="black"/>
    <path d="M55.3972 37.4484H30.4857C30.0008 37.4484 29.6033 37.0509 29.6033 36.566C29.6033 36.0812 30.0008 35.6836 30.4857 35.6836H55.3972C55.8821 35.6836 56.2796 36.0812 56.2796 36.566C56.2796 37.0509 55.8821 37.4484 55.3972 37.4484Z" fill="black"/>
    <path d="M55.3972 43.2981H30.4857C30.0008 43.2981 29.6033 42.9005 29.6033 42.4156C29.6033 41.9308 30.0008 41.5332 30.4857 41.5332H55.3972C55.8821 41.5332 56.2796 41.9308 56.2796 42.4156C56.2796 42.9005 55.8821 43.2981 55.3972 43.2981Z" fill="black"/>
  </svg>
);
