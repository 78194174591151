import React, { Box, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import TextTransition from 'react-text-transition';
import * as Styled from './landing-page.styled';
import { StainIcon } from './icons/stain-icon.component';
import { SquiggleIcon } from './icons/squiggle-icon';
import { HalfEllipseIcon } from './icons/half-ellipse-icon.component';
import { StartIcon } from './icons/star-icon.component';
import { ChevronIcon } from '../common/components/icons/chevron-icon.component';
import { ManyDotsIcon } from './icons/many-dots-icon.component';
import { headerWelcomeNames } from './landing-page.consts';
import { usePerspective } from '../common/hooks/use-perspective.hooks';
import { IWindowSize } from '../common/types/input.types';
import { WindowState } from '../theme';
import { getWindowState } from '../common/helpers/get-window-state.helper';

interface IProps {
  windowSize: IWindowSize;
}

export const HeaderContainer: FC<IProps> = ({ windowSize }) => {
  const [currentNameIndex, setCurrentNameIndex] = useState(0);
  const [currentWindowState, setCurrentWindowState] = useState<WindowState>();
  const interval = 2000;
  const manyDotsContainerRef = useRef<any>(null);
  const manyDotstInnerRef = useRef<any>(null);
  const squiggleContainerRef = useRef<any>(null);
  const squiggleInnerRef = useRef<any>(null);
  usePerspective(manyDotsContainerRef, manyDotstInnerRef, 7, 2);
  usePerspective(squiggleContainerRef, squiggleInnerRef, 7, 3);

  const nextName = () => {
    const timer = setInterval(() => {
      setCurrentNameIndex((prevIndex) => (prevIndex + 1) % headerWelcomeNames.length);
    }, interval);
    return () => {
      clearInterval(timer);
    };
  }

  useEffect(() => {
    return nextName();
  }, [interval]);

  useEffect(() => {
    const windowState = getWindowState(windowSize);
    if(!currentWindowState) {
      setCurrentWindowState(windowState);
      return;
    }
    if(windowState !== currentWindowState) {
      setCurrentWindowState(windowState);
      setCurrentNameIndex((prevIndex) => (prevIndex + 1) % headerWelcomeNames.length);
    }
  }, [windowSize]);

  return (
    <Styled.HeaderContainer>
      <Styled.HeaderSquiggleIconContainer ref={squiggleContainerRef}>
        <div ref={squiggleInnerRef}>
          <SquiggleIcon />
        </div>
      </Styled.HeaderSquiggleIconContainer>
      <Styled.HalfEllipseContainer>
        <HalfEllipseIcon />
      </Styled.HalfEllipseContainer>
      <Styled.StarContainer>
        <StartIcon />
      </Styled.StarContainer>
      <Styled.ManyDotsContainer ref={manyDotsContainerRef}>
        <div ref={manyDotstInnerRef}>
          <ManyDotsIcon />
        </div>
      </Styled.ManyDotsContainer>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
      >
        <Styled.HeaderTextContainer>
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Box display="flex" alignItems="center"> 
              <Styled.HeaderText variant="h1">
                Hey&nbsp;
              </Styled.HeaderText>
              <TextTransition inline direction="down">{headerWelcomeNames[currentNameIndex]}</TextTransition>
              <Styled.HeaderText variant="h1">
                !
              </Styled.HeaderText>
            </Box>
          </Box>
          <Typography variant="h2" component={Styled.HeaderInfoText}>
            Welcome to Near - Disrupting Remote Team Communication
          </Typography>
          <Styled.HeaderButtonContainer>
            <div>
              <Styled.HeaderButton
                target="_blank" 
                href={process.env.REACT_APP_REQUEST_ACCESS_URL} 
              >
                Request Access
              </Styled.HeaderButton>
            </div>
            <Styled.ChevronContainer marginTop={20}>
              <ChevronIcon />
            </Styled.ChevronContainer>
          </Styled.HeaderButtonContainer>
        </Styled.HeaderTextContainer>
        <Styled.StainContainer>
          <StainIcon />
        </Styled.StainContainer>
      </Box>
    </Styled.HeaderContainer>
  );
};
