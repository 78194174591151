import { FC } from "react";

export const VoiceMessageIcon: FC = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1566_10867)">
    <path d="M35.9188 1.39258H56.0668C59.3398 1.39258 61.9928 3.7284 61.9928 6.60973V21.2189C61.9928 24.1002 59.3398 26.4361 56.0668 26.4361H35.9188C32.6463 26.4361 29.9928 24.1002 29.9928 21.2189V6.60973C29.9928 3.7284 32.6463 1.39258 35.9188 1.39258Z" fill="#6BB3F2"/>
    <path d="M38.8506 25.6016L37.7837 34.1348L49.5171 25.6016" fill="#6BB3F2"/>
    <path d="M39.9178 13.8657C39.9178 15.0439 38.9628 15.999 37.7845 15.999C36.6063 15.999 35.6512 15.0439 35.6512 13.8657C35.6512 12.6875 36.6063 11.7324 37.7845 11.7324C38.9628 11.7324 39.9178 12.6875 39.9178 13.8657Z" fill="#EAF1F6"/>
    <path d="M48.452 13.8657C48.452 15.0439 47.4964 15.999 46.3182 15.999C45.14 15.999 44.1849 15.0439 44.1849 13.8657C44.1849 12.6875 45.14 11.7324 46.3182 11.7324C47.4964 11.7324 48.452 12.6875 48.452 13.8657Z" fill="#EAF1F6"/>
    <path d="M56.9852 13.8657C56.9852 15.0439 56.0301 15.999 54.8519 15.999C53.6736 15.999 52.7186 15.0439 52.7186 13.8657C52.7186 12.6875 53.6736 11.7324 54.8519 11.7324C56.0301 11.7324 56.9852 12.6875 56.9852 13.8657Z" fill="#EAF1F6"/>
    <path d="M0.45166 50.1338C-0.137695 50.1338 -0.615234 49.6562 -0.615234 49.0674V46.9336C-0.615234 46.3447 -0.137695 45.8672 0.45166 45.8672C1.04053 45.8672 1.51807 46.3447 1.51807 46.9336V49.0674C1.51807 49.6562 1.04053 50.1338 0.45166 50.1338Z" fill="black"/>
    <path d="M3.65283 51.2012C3.06396 51.2012 2.58643 50.7236 2.58643 50.1343V45.8677C2.58643 45.2783 3.06396 44.8008 3.65283 44.8008C4.24219 44.8008 4.71973 45.2783 4.71973 45.8677V50.1343C4.71973 50.7236 4.24219 51.2012 3.65283 51.2012Z" fill="black"/>
    <path d="M16.4517 51.2012C15.8623 51.2012 15.3848 50.7236 15.3848 50.1343V45.8677C15.3848 45.2783 15.8623 44.8008 16.4517 44.8008C17.0405 44.8008 17.5181 45.2783 17.5181 45.8677V50.1343C17.5181 50.7236 17.0405 51.2012 16.4517 51.2012Z" fill="black"/>
    <path d="M7.91737 53.333C7.32851 53.333 6.85097 52.8555 6.85097 52.2661V43.7329C6.85097 43.1436 7.32851 42.666 7.91737 42.666C8.50673 42.666 8.98427 43.1436 8.98427 43.7329V52.2661C8.98427 52.8555 8.50673 53.333 7.91737 53.333Z" fill="black"/>
    <path d="M12.1842 53.333C11.5953 53.333 11.1178 52.8555 11.1178 52.2661V43.7329C11.1178 43.1436 11.5953 42.666 12.1842 42.666C12.7735 42.666 13.2511 43.1436 13.2511 43.7329V52.2661C13.2511 52.8555 12.7735 53.333 12.1842 53.333Z" fill="black"/>
    <path d="M20.7183 55.4668C20.1289 55.4668 19.6514 54.9893 19.6514 54.3999V41.6001C19.6514 41.0107 20.1289 40.5332 20.7183 40.5332C21.3071 40.5332 21.7847 41.0107 21.7847 41.6001V54.3999C21.7847 54.9893 21.3071 55.4668 20.7183 55.4668Z" fill="black"/>
    <path d="M24.9854 57.5986C24.396 57.5986 23.9185 57.1211 23.9185 56.5317V39.4653C23.9185 38.876 24.396 38.3984 24.9854 38.3984C25.5742 38.3984 26.0518 38.876 26.0518 39.4653V56.5317C26.0518 57.1211 25.5742 57.5986 24.9854 57.5986Z" fill="black"/>
    <path d="M29.2496 64C28.6602 64 28.1827 63.5225 28.1827 62.9331V33.0669C28.1827 32.4775 28.6602 32 29.2496 32C29.8385 32 30.316 32.4775 30.316 33.0669V62.9331C30.316 63.5225 29.8385 64 29.2496 64Z" fill="black"/>
    <path d="M62.3188 50.1338C61.73 50.1338 61.2524 49.6562 61.2524 49.0674V46.9336C61.2524 46.3447 61.73 45.8672 62.3188 45.8672C62.9082 45.8672 63.3857 46.3447 63.3857 46.9336V49.0674C63.3857 49.6562 62.9082 50.1338 62.3188 50.1338Z" fill="black"/>
    <path d="M59.1196 51.2012C58.5303 51.2012 58.0527 50.7236 58.0527 50.1343V45.8677C58.0527 45.2783 58.5303 44.8008 59.1196 44.8008C59.7085 44.8008 60.186 45.2783 60.186 45.8677V50.1343C60.186 50.7236 59.7085 51.2012 59.1196 51.2012Z" fill="black"/>
    <path d="M46.3188 51.2012C45.73 51.2012 45.2524 50.7236 45.2524 50.1343V45.8677C45.2524 45.2783 45.73 44.8008 46.3188 44.8008C46.9082 44.8008 47.3857 45.2783 47.3857 45.8677V50.1343C47.3857 50.7236 46.9082 51.2012 46.3188 51.2012Z" fill="black"/>
    <path d="M54.8506 53.333C54.2612 53.333 53.7837 52.8555 53.7837 52.2661V43.7329C53.7837 43.1436 54.2612 42.666 54.8506 42.666C55.4394 42.666 55.917 43.1436 55.917 43.7329V52.2661C55.917 52.8555 55.4394 53.333 54.8506 53.333Z" fill="black"/>
    <path d="M50.5837 53.333C49.9944 53.333 49.5168 52.8555 49.5168 52.2661V43.7329C49.5168 43.1436 49.9944 42.666 50.5837 42.666C51.1726 42.666 51.6501 43.1436 51.6501 43.7329V52.2661C51.6501 52.8555 51.1726 53.333 50.5837 53.333Z" fill="black"/>
    <path d="M42.0518 55.4668C41.4629 55.4668 40.9854 54.9893 40.9854 54.3999V41.6001C40.9854 41.0107 41.4629 40.5332 42.0518 40.5332C42.6411 40.5332 43.1187 41.0107 43.1187 41.6001V54.3999C43.1187 54.9893 42.6411 55.4668 42.0518 55.4668Z" fill="black"/>
    <path d="M37.7827 57.5986C37.1938 57.5986 36.7163 57.1211 36.7163 56.5317V39.4653C36.7163 38.876 37.1938 38.3984 37.7827 38.3984C38.3721 38.3984 38.8496 38.876 38.8496 39.4653V56.5317C38.8496 57.1211 38.3721 57.5986 37.7827 57.5986Z" fill="black"/>
    <path d="M33.5183 64C32.9295 64 32.4519 63.5225 32.4519 62.9331V33.0669C32.4519 32.4775 32.9295 32 33.5183 32C34.1077 32 34.5852 32.4775 34.5852 33.0669V62.9331C34.5852 63.5225 34.1077 64 33.5183 64Z" fill="black"/>
    <path d="M56.9858 27.7334H48.0576C47.4687 27.7334 46.9912 27.2559 46.9912 26.6665C46.9912 26.0776 47.4687 25.6001 48.0576 25.6001H56.9858C59.3413 25.5977 61.25 23.689 61.2524 21.3335V6.3999C61.25 4.04443 59.3413 2.13574 56.9858 2.1333H35.6523C33.2968 2.13574 31.3882 4.04443 31.3857 6.3999V21.3335C31.3882 23.689 33.2968 25.5977 35.6523 25.6001H38.7246C39.3134 25.6001 39.791 26.0776 39.791 26.6665C39.791 27.2559 39.3134 27.7334 38.7246 27.7334H35.6523C32.1196 27.7295 29.2563 24.8662 29.2524 21.3335V6.3999C29.2563 2.86719 32.1196 0.00390625 35.6523 0H56.9858C60.5185 0.00390625 63.3818 2.86719 63.3857 6.3999V21.3335C63.3818 24.8662 60.5185 27.7295 56.9858 27.7334Z" fill="black"/>
    <path d="M37.7827 35.1999C37.477 35.1999 37.1855 35.0681 36.9834 34.8391C36.7808 34.6096 36.6865 34.3044 36.7246 34.0007L37.791 25.4675C37.834 25.0857 38.0791 24.7561 38.4321 24.6052C38.7856 24.4543 39.1929 24.5051 39.4985 24.7385C39.8042 24.9719 39.9604 25.3513 39.9077 25.7322L39.147 31.8225L48.8887 24.7371C49.3647 24.3904 50.0322 24.4958 50.3784 24.9719C50.7251 25.4485 50.6201 26.1155 50.1435 26.4622L38.4106 34.9954C38.228 35.1282 38.0083 35.1995 37.7827 35.1999Z" fill="black"/>
    <path d="M37.7827 12.8008C37.1938 12.8008 36.7163 13.2783 36.7163 13.8677C36.7163 14.4565 37.1938 14.9341 37.7827 14.9341C38.3721 14.9341 38.8496 14.4565 38.8496 13.8677C38.8491 13.2788 38.3716 12.8013 37.7827 12.8008Z" fill="#EAF1F6"/>
    <path d="M46.3188 12.8008C45.73 12.8008 45.2524 13.2783 45.2524 13.8677C45.2524 14.4565 45.73 14.9341 46.3188 14.9341C46.9082 14.9341 47.3857 14.4565 47.3857 13.8677C47.3853 13.2788 46.9082 12.8013 46.3188 12.8008Z" fill="#EAF1F6"/>
    <path d="M54.8506 12.8008C54.2612 12.8008 53.7837 13.2783 53.7837 13.8677C53.7837 14.4565 54.2612 14.9341 54.8506 14.9341C55.4395 14.9341 55.917 14.4565 55.917 13.8677C55.9165 13.2788 55.4395 12.8013 54.8506 12.8008Z" fill="#EAF1F6"/>
    </g>
    <defs>
    <clipPath id="clip0_1566_10867">
    <rect width="64" height="64" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);
