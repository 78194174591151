import React from 'react';

export const SquiggleIcon = () => (
  <svg width="130" height="88" viewBox="0 0 130 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M3.80307 26.2367C19.0771 -7.26594 29.2759 -5.78632 34.3997 30.6756C39.5235 67.1374 49.7223 68.617 64.9963 35.1144C80.2703 1.61179 90.4692 3.09142 95.593 39.5533C100.717 76.0152 110.916 77.4948 126.19 43.9922"
      stroke="black"
      strokeWidth="3"
    />
  </svg>
);
