import React, { FC, useRef } from 'react';
import * as Styled from './landing-page.styled';
import EffortlessFriendsGroup from './images/Group 1000002538friends-group.png';
import EffortlessContact from './images/Frame 1000002587contact.png';
import { IMousePosition } from '../common/types/input.types';
import { COLORS, SPACES } from '../theme';
import { DotIcon } from './icons/dot-icon.component';
import { SquiggleIcon } from './icons/squiggle-icon';
import { Grid } from '@mui/material';
import { usePerspective } from '../common/hooks/use-perspective.hooks';

interface IProps {
  mousePosition: IMousePosition;
}

export const EffortlessCommunicationContainer: FC<IProps> = ({ mousePosition }) => {
  const squiggleContainerRef = useRef<any>(null);
  const squiggleInnerRef = useRef<any>(null);
  usePerspective(squiggleContainerRef, squiggleInnerRef, 7, 2);
  return (
    <Styled.EffortlessCommunicationContainer>
      <Styled.EffortlessFriendsGroupContainer>
        <img height="377px" src={EffortlessFriendsGroup} alt="EffortlessFriendsGroup" />
      </Styled.EffortlessFriendsGroupContainer>
      <Styled.EffortlessContactContainer
        mouseX={mousePosition.x}
        mouseY={mousePosition.y}
        windowInnerWidth={window.innerWidth}
        windowInnerHeight={window.innerHeight}
        parallaxEffect={-7}
      >
        <img height="101px" src={EffortlessContact} alt="EffortlessContact" />
      </Styled.EffortlessContactContainer>
      <Styled.EffortlessTextContainer maxWidth="xl">
        <Styled.EffortlessDotContainer>
          <DotIcon color={COLORS.lightPurple} radius={36} />
          <DotIcon color={COLORS.lightPurple} radius={36} />
          <DotIcon color={COLORS.lightPurple} radius={36} />
        </Styled.EffortlessDotContainer>
        <Styled.EffortlessSquiggleContainer ref={squiggleContainerRef}>
          <div ref={squiggleInnerRef}>
            <SquiggleIcon />
          </div>
        </Styled.EffortlessSquiggleContainer>
        <Grid container>
          <Grid item xl={6} lg={5} md={4} sm={12} xs={12} zIndex={2}>
            <Styled.Title variant="h4">Effortless Communication</Styled.Title>
            <Styled.SubText variant="body1" marginTop={SPACES.xxxxl}>
              Near’s innovative <Styled.TextBold>“Hey Name”</Styled.TextBold> feature makes communication feel as natural as speaking. Just
              say <Styled.TextBold>“Hey Name”</Styled.TextBold> to instantly connect with a team member and start a conversation without the
              need to dial numbers or click buttons.
            </Styled.SubText>
            <Styled.SubText variant="body1" marginTop={SPACES.xl}>
              When you’re ready to end the call, simply say <Styled.TextBold>“Bye-bye”</Styled.TextBold> to end the conversation.
            </Styled.SubText>
          </Grid>
        </Grid>
      </Styled.EffortlessTextContainer>
    </Styled.EffortlessCommunicationContainer>
  )
};
