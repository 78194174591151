import React from 'react';

export const HalfEllipseIcon = () => (
  <svg
    width="462"
    height="231"
    viewBox="0 0 462 231"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M462 -4.93587e-06C462 61.265 437.663 120.021 394.342 163.342C351.021 206.663 292.265 231 231 231C169.735 231 110.979 206.663 67.6583 163.342C24.3374 120.021 -8.14993e-06 61.265 -1.00973e-05 0L108.98 -4.76366e-06C108.98 32.3618 121.836 63.398 144.719 86.2812C167.602 109.164 198.638 122.02 231 122.02C263.362 122.02 294.398 109.164 317.281 86.2813C340.164 63.398 353.02 32.3617 353.02 -1.5431e-05L462 -4.93587e-06Z"
      fill="#FFFAD0"
    />
  </svg>
);
