import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import * as Styled from './landing-page.styled';
import { HeaderContainer } from './header-container.component';
import { NavbarComponent } from './navbar.component';
import { CollaborationContainer } from './collaboration-container.component';
import { EffortlessCommunicationContainer } from './effortless-communication-container.component';
import { SameRoomContainer } from './same-room-container.component';
import { WhosNearForContainer } from './whos-near-for-container.component';
import { SPACES, WindowState } from '../theme';
import { BenefitsContainer } from './benefits-container.component';
import { FooterContainer } from './footer-container.component';
import { useMousePosition } from '../common/hooks/use-mouse-position.hooks';
import { useWindowSize } from '../common/hooks/use-window-size.hooks';
import { getWindowState } from '../common/helpers/get-window-state.helper';

interface IProps {}

export const LandingPage: FC<IProps> = () => {
  const [currentWindowState, setCurrentWindowState] = useState<WindowState>("xs");
  const mousePosition = useMousePosition();
  const windowSize = useWindowSize();
  useEffect(() => {
    const windowState = getWindowState(windowSize);
    setCurrentWindowState(windowState);
  }, [windowSize]);
  return (
    <>
      <NavbarComponent />
      <Styled.NavbarSpace />
      <HeaderContainer windowSize={windowSize} />
      <CollaborationContainer mousePosition={mousePosition} windowState={currentWindowState} />
      <Box marginTop={{ md: 25, sm: 15, xs: 15 }}>
        <EffortlessCommunicationContainer mousePosition={mousePosition} />
      </Box>
      <Box marginTop={{ md: 25, sm: 35, xs: 45 }}>
        <SameRoomContainer />
      </Box>
      <Box marginTop={{ md: SPACES.xxl, sm: 15, xs: 15 }}>
        <WhosNearForContainer />
      </Box>
      <Box marginTop={{ md: 20, sm: 15, xs: 15 }}>
        <BenefitsContainer />
      </Box>
      <FooterContainer />
    </>
  );
};
