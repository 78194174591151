import React, { FC, useRef } from 'react';
import * as Styled from './landing-page.styled';
import { IBenefitsBlock } from './landing-page.types';
import { usePerspective } from '../common/hooks/use-perspective.hooks';
import { Grid } from '@mui/material';

interface IProps {
  block: IBenefitsBlock;
}

export const BenefitsBlockComponent: FC<IProps> = ({ block }) => {
  const perspectiveContainerRef = useRef<any>(null);
  const perspectiveInnerRef = useRef<any>(null);
  usePerspective(perspectiveContainerRef, perspectiveInnerRef, 50);
  return (
    <Grid item md={block.blockSize} sm={12} position="relative" ref={perspectiveContainerRef}>
      <Styled.BenefitsGridBlock ref={perspectiveInnerRef}>
        <Styled.BenefitsBlockNumberContainer>
          <Styled.BenefitsBlockNumber>{block.number}</Styled.BenefitsBlockNumber>
        </Styled.BenefitsBlockNumberContainer>
        <Styled.BenefitsBlockHeader>
          <block.imgSrc />
          <Styled.SubTitle variant="body1">{block.subTitle}</Styled.SubTitle>
        </Styled.BenefitsBlockHeader>
        <Styled.BenefitsBlockSubText variant="body1">
          {block.subText}
        </Styled.BenefitsBlockSubText>
      </Styled.BenefitsGridBlock>
    </Grid>
  )
}