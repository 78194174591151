import React from 'react';

export const StainIcon = () => (
  <svg
    width="1207"
    height="482"
    viewBox="0 0 1207 482"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M1166.69 173.971C1166.69 173.971 1011.14 191.634 1166.69 340.718C1322.23 489.803 966.656 502.497 764.779 462.447C562.902 422.397 398.963 361.144 223.209 393.305C47.4556 425.465 -126.658 279.732 128.993 226.106C447.011 159.398 232.67 152.67 271.474 71.7209C310.277 -9.22801 587.204 -26.194 740.988 44.2531C894.773 114.7 878.729 116.764 966.886 60.4036C1055.04 4.04313 1303.6 118.727 1166.69 173.971Z"
      fill="#fff"
    />
  </svg>
);
