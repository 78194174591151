const breakpoints = {
  xs: "425px",
  sm: "768px",
  md: "1024px",
  lg: "1280px",
  xl: "1920px"
};

export const breakpointsNumber = {
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1920
};

export const devices = {
  xs: `(max-width: ${breakpoints.xs})`,
  sm: `(max-width: ${breakpoints.sm})`,
  md: `(max-width: ${breakpoints.md})`,
  lg: `(max-width: ${breakpoints.lg})`,
  xl: `(max-width: ${breakpoints.xl})`
};

export type WindowState = "xs" | "sm" | "md" | "lg" | "xl";
