import React, { FC } from 'react';
import { COLORS } from '../../theme';

interface IProps {
  color?: string;
  radius?: number;
}

export const DotIcon: FC<IProps> = ({ color, radius }) => (
  <svg
    width={radius ? radius * 2 : '46'}
    height={radius ? radius * 2 : '46'}
    viewBox={radius ? `0 0 ${radius * 2} ${radius * 2}` : '0 0 46 46'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={radius || '23'}
      cy={radius || '23'}
      r={radius || '23'}
      fill={color || COLORS.black}
    />
  </svg>
);
