import React, { FC, useRef } from 'react';
import { Box } from '@mui/material';
import * as Styled from './landing-page.styled';
import { SPACES } from '../theme';
import { WaveIcon } from './icons/wave-icon.component';
import { SmallHalfEllipseIcon } from './icons/small-half-ellipse-icon.component';
import { DotIcon } from './icons/dot-icon.component';
import { SquiggleIcon } from './icons/squiggle-icon';
import { ManyDotsIcon } from './icons/many-dots-icon.component';
import { usePerspective } from '../common/hooks/use-perspective.hooks';

export const FooterContainer: FC = () => {
  const squiggleContainerRef = useRef<any>(null);
  const squiggleInnerRef = useRef<any>(null);
  const manyDotsContainerRef = useRef<any>(null);
  const manyDotsInnerRef = useRef<any>(null);
  usePerspective(squiggleContainerRef, squiggleInnerRef, 7, 2);
  usePerspective(manyDotsContainerRef, manyDotsInnerRef, 7, 2);
  return (
    <Styled.FooterContainer>
      <Styled.WaveContainer>
        <WaveIcon />
      </Styled.WaveContainer>
      <Styled.FooterEllipseDotSMDeviceContainer>
        <Styled.SmallHalfEllipseContainer>
          <SmallHalfEllipseIcon />
        </Styled.SmallHalfEllipseContainer>
        <Styled.FooterDotContainer>
          <DotIcon radius={16} />
        </Styled.FooterDotContainer>
      </Styled.FooterEllipseDotSMDeviceContainer>
      <Styled.FooterManyDotsContainer ref={manyDotsContainerRef}>
        <div ref={manyDotsInnerRef}>
          <ManyDotsIcon />
        </div>
      </Styled.FooterManyDotsContainer>
      <Styled.FooterInfoContainer maxWidth="xl">
        <Styled.FooterTextContainer>
          <Box position="relative" zIndex={2} width="100%">
            <Styled.FooterTitle>
              Experience the future of team communication with Near!
            </Styled.FooterTitle>
            <Box marginTop={SPACES.l}>
              <Styled.FooterText>
                Request access today to revolutionize your remote team’s collaboration. Don’t let
                distance and outdated communication methods hold you back.
              </Styled.FooterText>
            </Box>
          </Box>
          <Styled.FooterEllipseDotContainer>
            <Styled.SmallHalfEllipseContainer>
              <SmallHalfEllipseIcon />
            </Styled.SmallHalfEllipseContainer>
            <Styled.FooterDotContainer>
              <DotIcon radius={16} />
            </Styled.FooterDotContainer>
          </Styled.FooterEllipseDotContainer>
          <Styled.FooterSquiggleContainer ref={squiggleContainerRef}>
            <div ref={squiggleInnerRef}>
              <SquiggleIcon />
            </div>
          </Styled.FooterSquiggleContainer>
        </Styled.FooterTextContainer>
        <Styled.FooterButtonContainer>
          <Styled.HeaderButton 
            target="_blank" 
            href={process.env.REACT_APP_REQUEST_ACCESS_URL} 
          >
            Request Access
          </Styled.HeaderButton>
        </Styled.FooterButtonContainer>
      </Styled.FooterInfoContainer>
    </Styled.FooterContainer>
  );
};
