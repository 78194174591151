import { Container, Typography, Theme } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import { COLORS, FONTS, SPACES, devices } from '../theme';
import { SIZES, WEIGHTS } from '../theme/fonts.const';

export const NavbarWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 87px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const NavbarSpace = styled.div`
  height: 87px;
  background-color: ${COLORS.white};
`;

export const HeaderContainer = styled.div`
  background: ${COLORS.skyBlue};
  height: 737px;
  position: relative;
`;

export const Button = styled.a`
  border: 1px solid ${COLORS.black};
  border-radius: 50px;
  font-family: ${FONTS.FAMILIES.inter};
  letter-spacing: 0.02em;
  font-size: ${SIZES.xl};
  font-weight: ${WEIGHTS.medium};
  padding: ${SPACES.xxs} ${SPACES.l};
  text-decoration: none;
  color: ${COLORS.black};
  cursor: pointer;
`;

export const NavbarButton = styled(Button)`
  background: ${COLORS.lightSkyBlue};
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background 300ms ease-in-out;
  font-weight: ${FONTS.WEIGHTS.medium};
  :hover {
    background: ${COLORS.skyBlue};
  }
`;

export const HeaderButton = styled(Button)`
  background: ${COLORS.black};
  color: ${COLORS.white};
  padding: ${SPACES.s} 2rem;
  transition: background 300ms ease-in-out, color 300ms ease-in-out;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: ${FONTS.SIZES.xxl};
  :hover {
    background: ${COLORS.white};
    color: ${COLORS.black};
  }
`;

interface IParralaxProps {
  mouseX: number;
  mouseY: number;
  windowInnerWidth: number;
  windowInnerHeight: number;
  parallaxEffect: number;
  positionX?: number;
  positionY?: number;
}

interface IFigureProps {
  rotate?: number;
  position?: string;
  zIndex?: number;
}

export const Figure = styled.div<IFigureProps>`
  position: ${(props) => (props.position ? props.position : 'absolute')};
  z-index: ${(props) => (props.zIndex ? props.zIndex : 1)};
  rotate: ${(props) => (props.rotate ? `${props.rotate}deg` : 'default')};
`;

export const Parallax = styled(Figure).attrs<IParralaxProps>((props) => ({
  style: {
    transform: `translate(
      ${(props.mouseX / props.windowInnerWidth - 0.5) * (props.parallaxEffect * 2) +
      (props.positionX ? props.positionX : 0)}px,
      ${(props.mouseY / props.windowInnerHeight - 0.5) * (props.parallaxEffect * 2) +
      (props.positionY ? props.positionY : 0)}px
    )`,
  },
}))<IParralaxProps>``;

interface IPerspectiveContainer {
  perspective?: number;
}

export const PerspectiveContainer = styled(Figure)<IPerspectiveContainer>`
  perspective: ${(props) => props.perspective ? `${props.perspective}px` : '30px' };
`;

export const PerspectiveInner = styled.div`
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
`;

export const ParallaxImg = styled(Parallax)`
  border: 1px solid rgba(0, 0, 0, 1);
  box-shadow: -5px 5px 0px 0px rgba(0, 0, 0, 1);
`;

export const HeaderSquiggleIconContainer = styled(Figure)`
  top: 150px;
  left: -30px;
  @media ${devices.sm} {
    /* display: none; */
    display: flex;
    align-items: flex-end;
    bottom: 50px;
  }
`;

export const HalfEllipseContainer = styled(Figure)`
  left: -130px;
  bottom: -110px;
  rotate: 20deg;
`;

export const StarContainer = styled(Figure)`
  right: 0;
  bottom: -260px;
  @media ${devices.sm} {
    right: -100px;
    transform: scale(0.6);
  }
`;

export const ManyDotsContainer = styled(Figure)`
  right: 30px;
  bottom: 115px;
`;

export const BoldSquiggleContainer = styled(Figure)`
  right: 0;
  bottom: -230px;
  z-index: 0;
`;

export const NameTransitionContainer = styled(Typography)`
  position: absolute;
  right: 0;
`;

export const HeaderText = styled(Typography)`
  font-family: 'KyivTypeSans', sans-serif !important;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: 98px;
  @media ${devices.md} {
    font-size: 80px;
  }
  @media ${devices.sm} {
    font-size: 60px;
  }
  @media ${devices.xs} {
    font-size: 42px;
  }
`;

export const HeaderInfoText = styled(HeaderText)`
  margin-top: 1rem !important;
  font-size: 60px;
  @media ${devices.md} {
    margin-top: 3rem !important;
    font-size: 42px;
  }
  @media ${devices.sm} {
    font-size: 36px;
  }
  @media ${devices.xs} {
    font-size: 28px !important;
  }
`;

export const StainContainer = styled.div`
  position: absolute;
  top: ${SPACES.xs};
`;

export const HeaderTextContainer = styled.div`
  position: relative;
  top: 70px;
  z-index: 1;
  text-align: center;
  max-width: 1000px;
  .text-transition {
    position: relative;
    overflow: hidden;
    height: 144px !important;
    @media ${devices.md} {
      height: 120px !important;
    }
    @media ${devices.sm} {
      height: 90px !important;
    }
    @media ${devices.xs} {
      height: 63px !important;
    }
  }
  .text-transition div {
    width: auto;
    font-size: 98px;
    font-weight: ${FONTS.WEIGHTS.bold};
    font-family: 'KyivTypeSansMedium', sans-serif !important;
    @media ${devices.md} {
      font-size: 80px;
    }
    @media ${devices.sm} {
      font-size: 60px;
    }
    @media ${devices.xs} {
      font-size: 42px;
    }
  }
`;

export const HeaderButtonContainer = styled.div`
  margin-top: 120px;
  @media ${devices.md} {
    margin-top: 130px;
  }
`;

interface IChevronContainerProps {
  marginTop?: number;
  rotate?: number;
}

export const ChevronContainer = styled.div<IChevronContainerProps>`
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : 'default')};
  rotate: ${(props) => (props.rotate ? `${props.rotate}deg` : 'default')};
`;

export const CollaborationContainer = styled.div`
  height: 440px;
  position: relative;
  @media ${devices.lg} {
    height: 350px;
  }
  @media ${devices.md} {
    height: 300px;
  }
  @media ${devices.sm} {
    height: 100%;
  }
`;

export const CollabMainContainer = styled(Figure)`
  top: -100px;
  border: 1px solid rgba(0, 0, 0, 1);
  box-shadow: -5px 5px 0px 0px rgba(0, 0, 0, 1);
  @media ${devices.lg} {
    img {
      height: 445px;
    }
  }
  @media ${devices.md} {
    img {
      height: 400px;
    }
  }
  @media ${devices.sm} {
    left: -330px;
    top: -180px;
  }
  @media ${devices.xs} {
    left: -500px;
  }
`;

export const ImgShadow = styled.div`
  img {
    border: 1px solid rgba(0, 0, 0, 1);
    box-shadow: -5px 5px 0px 0px rgba(0, 0, 0, 1);
  }
`;

export const CollabLoginContainer = styled(ParallaxImg)`
  @media ${devices.lg} {
    img {
      height: 205px;
    }
  }
  @media ${devices.sm} {
    transform: translate(370px, 60px) !important;
    left: 0;
    bottom: 0;
    img {
      height: 135px;
    }
  }
  @media ${devices.xs} {
    transform: translate(200px, 60px) !important;
  }
`;

export const CollabFriendsContainer = styled(ParallaxImg)`
  @media ${devices.lg} {
    img {
      height: 157px;
    }
  }
  @media ${devices.md} {
    img {
      height: 130px;
    }
  }
  @media ${devices.sm} {
    transform: translate(270px, 200px) !important;
    left: 0;
    bottom: 0;
    img {
      height: 120px;
    }
  }
  @media ${devices.xs} {
    transform: translate(100px, 200px) !important;
  }
`;

export const CollabInviteContainer = styled(ParallaxImg)`
  @media ${devices.lg} {
    img {
      height: 31px;
    }
  }
  @media ${devices.sm} {
    transform: translate(300px, -90px) !important;
    left: 0;
    bottom: 0;
  }
  @media ${devices.xs} {
    transform: translate(130px, -90px) !important;
  }
`;

export const CollabDotContainer = styled(PerspectiveContainer)`
  transform: translate(-610px, 270px);
  @media ${devices.lg} {
    transform: translate(-510px, 235px);
  }
  @media ${devices.md} {
    transform: translate(-420px, 205px);
  }
  @media ${devices.sm} {
    transform: translate(0, 0) scale(0.6);
    left: 475px;
    bottom: -110px;
    z-index: 1;
  }
  @media ${devices.xs} {
    left: 305px;
  }
`;

export const EffortlessCommunicationContainer = styled.div`
  position: relative;
  height: 376px;
`;

export const EffortlessFriendsGroupContainer = styled(Figure)`
  right: 0;
  z-index: 4;
  @media ${devices.md} {
    right: -100px;
  }
  @media ${devices.sm} {
    bottom: -210px;
    img {
      height: 230px;
    }
  }
  @media ${devices.xs} {
    bottom: -250px;
    img {
      height: 200px;
    }
  }
`;

export const EffortlessContactContainer = styled(Parallax)`
  right: -5px;
  top: 165px;
  z-index: 5;
  @media ${devices.md} {
    right: -105px;
  }
  @media ${devices.sm} {
    transform: translate(30px, 285px) !important;
    img {
      height: 70px;
    }
  }
  @media ${devices.xs} {
    transform: translate(30px, 345px) !important;
    img {
      height: 60px;
    }
  }
`;

export const EffortlessTextContainer = styled(Container)`
  position: relative;
  height: 380px;
  z-index: 3;
`;

export const Title = styled(Typography)`
  font-size: 38px;
  font-family: 'KyivTypeSansMedium', sans-serif !important;
  font-weight: ${FONTS.WEIGHTS.medium};
  @media ${devices.xs} {
    font-size: 30px;
  }
`;

interface ISubTextProps {
  width?: string;
  fontFamily?: string;
}

export const SubText = styled(Typography)<ISubTextProps>`
  font-size: ${FONTS.SIZES.xl};
  font-family: ${(props) => props.fontFamily ? props.fontFamily: FONTS.FAMILIES.inter};
  max-width: ${(props) => (props.width ? props.width : 'auto')};
  @media ${devices.md} {
    max-width: 100% !important;
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const TextBold = styled.span`
  font-size: ${FONTS.SIZES.xl};
  font-weight: ${FONTS.WEIGHTS.medium};
  font-family: ${FONTS.FAMILIES.dmSans};
`

export const SubTitle = styled(Typography)<{ theme?: Theme }>`
  font-size: ${FONTS.SIZES.xxxl};
  font-weight: ${FONTS.WEIGHTS.medium};
  font-family: ${FONTS.FAMILIES.inter};
  @media ${devices.sm} {
    max-width: 200px !important;
  }
`;

export const EffortlessDotContainer = styled(Figure)`
  bottom: 0;
  left: 20px;
  gap: 30px;
  display: flex;
`;

export const EffortlessSquiggleContainer = styled(Figure)`
  left: 250px;
  bottom: -50px;
  @media ${devices.sm} {
    left: 20px;
    bottom: -90px;
    left: auto;
  }
  @media ${devices.xs} {
    bottom: -150px;
  }
`;

export const SameRoomContainer = styled(Container)`
  position: relative;
  min-height: 478px;
`;

export const SameRoomRequestAccessContainer = styled(Figure)`
  bottom: 0;
  right: 0;
`;

export const SameRoomHalfEllipsesContainer = styled(Figure)`
  z-index: 0;
  display: flex;
  gap: 20px;
  left: 20px;
  bottom: 0;
`;

export const WhosNearForContainer = styled.div`
  position: relative;
`;

export const WhosNearForInfoContainer = styled(Container)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const WhosNearForStarContainer = styled(Figure)`
  z-index: 0;
  right: 0;
  bottom: -240px;
  @media ${devices.xs} {
    transform: scale(0.7);
    right: -65px;
  }
`;

export const BenefitsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;

export const BenefitsGridBlock = styled.div`
  padding: ${SPACES.l};
  background-color: ${COLORS.lightGreen};
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
`;

export const BenefitsBlockHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
`;

export const BenefitsBlockNumberContainer = styled(Figure)`
  right: 0;
`;

export const BenefitsBlockNumber = styled(Typography)`
  font-family: 'KyivTypeSans', sans-serif !important;
  font-weight: ${FONTS.WEIGHTS.bold};
  font-size: 114px;
  -webkit-text-stroke: 1px ${COLORS.black};
  color: ${COLORS.white};
  text-shadow: ${COLORS.black} -5px 5px;
  line-height: 0.9;
`;

export const BenefitsBlockSubText = styled(SubText)`
  font-family: ${FONTS.FAMILIES.inter};
`;

export const BenefitsDotContainer = styled(Figure)`
  left: 5px;
  bottom: -30px;
`;

export const FooterContainer = styled.div`
  position: relative;
  padding: 10rem 0;
`;

export const FooterInfoContainer = styled(Container)`
  position: relative;
  z-index: 1;
`;

export const FooterTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const FooterTitle = styled(Title)`
  font-size: 44px;
  font-weight: ${FONTS.WEIGHTS.bold} !important;
  width: 80%;
  @media ${devices.sm} {
    width: 100%;
    font-size: 35px;
  }
`;

export const FooterText = styled(SubText)`
  width: 55%;
  font-family: ${FONTS.FAMILIES.inter};
  @media ${devices.sm} {
    width: 100%;
  }
`;

export const WaveContainer = styled(Figure)`
  bottom: 0;
  height: auto;
  width: 100%;
  z-index: 0;
  svg {
    display: block;
  }
`;

export const FooterEllipseDotSMDeviceContainer = styled.div`
  display: none;
  @media ${devices.sm} {
    display: block;
    position: absolute;
    bottom: 30px;
    left: 150px;
    transform: scale(0.5);
  }
`;

export const FooterEllipseDotContainer = styled.div`
  @media ${devices.sm} {
    display: none;
  }
`;

const rotateEllipseAnimation = keyframes`
  0% { 
    rotate: -20deg;
    animation-timing-function: cubic-bezier(.8,0,1,1); 
  }
  50% { 
    rotate: 0deg; 
    animation-timing-function: cubic-bezier(0,0,.2,1);
  }
  100% { 
    rotate: 20deg; 
  }
`;

export const SmallHalfEllipseContainer = styled(Figure)`
  z-index: 0;
  right: 0;
  bottom: 0;
  animation-name: ${rotateEllipseAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

const rotateDotAnimation = keyframes`
  0% { 
    transform: translate(-195px, -80px);
    animation-timing-function: cubic-bezier(.8,0,1,1);
  }
  50% { 
    transform: translate(-120px, -60px);
    animation-timing-function: cubic-bezier(0,0,.2,1);
  }
  100% { 
    transform: translate(-40px, -80px);
  }
`;

export const FooterDotContainer = styled(Figure)`
  z-index: 0;
  right: 0;
  bottom: 0;
  animation-name: ${rotateDotAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

export const FooterSquiggleContainer = styled(Figure)`
  right: -90px;
  bottom: 20px;
  @media ${devices.sm} {
    bottom: -100px;
  }
`;

export const FooterManyDotsContainer = styled(Figure)`
  right: 60px;
  bottom: 60px;
  @media ${devices.sm} {
    bottom: 10px;
    right: 20px;
    transform: scale(0.8);
  }
`;

export const FooterButtonContainer = styled.div`
  margin-top: 3rem;
  @media ${devices.sm} {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;
