import React from 'react';
import * as Styled from './landing-page.styled';
import { FONTS, SPACES } from '../theme';
import { CardCarousel } from '../common/components/card-carousel/card-carousel.component';
import { lpCarouselCards } from './landing-page.consts';
import { StartIcon } from './icons/star-icon.component';
import { Grid } from '@mui/material';

export const WhosNearForContainer = () => (
  <Styled.WhosNearForContainer>
    <Styled.WhosNearForStarContainer>
      <StartIcon />
    </Styled.WhosNearForStarContainer>
    <Styled.WhosNearForInfoContainer maxWidth="xl">
      <Grid container>
        <Grid item md={8} sm={12} xs={12} zIndex={1}>
          <Styled.Title variant="h4">Who is Near for?</Styled.Title>
          <Styled.SubText 
            fontFamily={FONTS.FAMILIES.inter} 
            width="80%" 
            variant="body1" 
            marginTop={SPACES.xxxxl}
          >
            With Near, you can collaborate with colleagues seamlessly, stay in touch with loved ones
            effortlessly, and coordinate activities and events.
          </Styled.SubText>
        </Grid>
        <Grid 
          item
          md={4} 
          sm={12} 
          xs={12} 
          zIndex={1} 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          <CardCarousel cards={lpCarouselCards} interval={3000} maxCardHeight={138} />
        </Grid>
      </Grid>
    </Styled.WhosNearForInfoContainer>
  </Styled.WhosNearForContainer>
);
