import React from 'react';

export const WaveIcon = () => (
  <svg viewBox="0 0 1440 438" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M1799.62 422.533C1593.4 294.363 1763.78 227.037 1763.78 227.037C1893.27 160.506 1731.75 242.992 1647.82 256.764C1584.66 291.717 1572.57 162.517 1433.53 103.473C1277.8 37.3421 1034.89 68.285 980.267 144.757C925.643 221.23 833 314.005 724 353.535C615 393.064 -22.253 458.798 -179.5 144.751C-336.747 -169.295 -170.522 457.688 -145.753 593.912L815.939 513.996L889.045 507.921C1057.43 466.584 1190.82 578.418 1414.21 590.047C1586.66 599.023 1982.77 536.361 1799.62 422.533Z"
      fill="#E3F4FB"
    />
    <rect x="965" y="191" width="663" height="16" fill="white" />
    <rect x="930" y="229" width="663" height="16" fill="white" />
    <rect x="965" y="267" width="663" height="16" fill="white" />
    <rect x="930" y="305" width="663" height="16" fill="white" />
  </svg>
);
