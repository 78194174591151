/* eslint-disable max-len */
import React from 'react';

export const ManyDotsIcon = () => (
  <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M63.6937 10.6978C60.9643 12.2736 57.4793 11.3473 55.9098 8.6288C54.3403 5.9103 55.2805 2.42907 58.0099 0.853261C60.7393 -0.722552 64.2243 0.20378 65.7938 2.92228C67.3633 5.64078 66.4231 9.12201 63.6937 10.6978Z"
      fill="black"
    />
    <path
      d="M74.2492 28.9806C71.5198 30.5564 68.0349 29.6301 66.4654 26.9116C64.8958 24.1931 65.8361 20.7118 68.5655 19.136C71.2949 17.5602 74.7798 18.4865 76.3493 21.205C77.9189 23.9235 76.9786 27.4048 74.2492 28.9806Z"
      fill="black"
    />
    <path
      d="M84.8048 47.2633C82.0754 48.8392 78.5904 47.9128 77.0209 45.1943C75.4514 42.4758 76.3916 38.9946 79.121 37.4188C81.8504 35.843 85.3354 36.7693 86.9049 39.4878C88.4744 42.2063 87.5342 45.6875 84.8048 47.2633Z"
      fill="black"
    />
    <path
      d="M94.9544 64.8429C92.225 66.4187 88.74 65.4924 87.1705 62.7739C85.601 60.0554 86.5412 56.5742 89.2706 54.9984C92 53.4225 95.4849 54.3489 97.0545 57.0674C98.624 59.7859 97.6837 63.2671 94.9544 64.8429Z"
      fill="black"
    />
    <path
      d="M45.3377 21.2956C42.6083 22.8715 39.1234 21.9451 37.5538 19.2266C35.9843 16.5081 36.9246 13.0269 39.6539 11.4511C42.3833 9.87527 45.8683 10.8016 47.4378 13.5201C49.0073 16.2386 48.0671 19.7198 45.3377 21.2956Z"
      fill="black"
    />
    <path
      d="M55.8933 39.5784C53.1639 41.1542 49.6789 40.2279 48.1094 37.5094C46.5399 34.7909 47.4801 31.3097 50.2095 29.7338C52.9389 28.158 56.4238 29.0844 57.9934 31.8029C59.5629 34.5214 58.6226 38.0026 55.8933 39.5784Z"
      fill="black"
    />
    <path
      d="M66.4488 57.8612C63.7194 59.437 60.2345 58.5106 58.6649 55.7921C57.0954 53.0736 58.0357 49.5924 60.765 48.0166C63.4944 46.4408 66.9794 47.3671 68.5489 50.0856C70.1184 52.8041 69.1782 56.2854 66.4488 57.8612Z"
      fill="black"
    />
    <path
      d="M76.5984 75.4407C73.869 77.0166 70.384 76.0902 68.8145 73.3717C67.245 70.6532 68.1852 67.172 70.9146 65.5962C73.644 64.0204 77.129 64.9467 78.6985 67.6652C80.268 70.3837 79.3278 73.8649 76.5984 75.4407Z"
      fill="black"
    />
    <path
      d="M26.9817 31.8935C24.2523 33.4693 20.7674 32.543 19.1979 29.8245C17.6283 27.106 18.5686 23.6247 21.298 22.0489C24.0274 20.4731 27.5123 21.3994 29.0818 24.1179C30.6514 26.8364 29.7111 30.3177 26.9817 31.8935Z"
      fill="black"
    />
    <path
      d="M37.5373 50.1762C34.8079 51.752 31.3229 50.8257 29.7534 48.1072C28.1839 45.3887 29.1241 41.9075 31.8535 40.3317C34.5829 38.7559 38.0679 39.6822 39.6374 42.4007C41.2069 45.1192 40.2667 48.6004 37.5373 50.1762Z"
      fill="black"
    />
    <path
      d="M48.0928 68.459C45.3634 70.0348 41.8785 69.1085 40.309 66.39C38.7394 63.6715 39.6797 60.1902 42.4091 58.6144C45.1385 57.0386 48.6234 57.9649 50.1929 60.6834C51.7625 63.4019 50.8222 66.8832 48.0928 68.459Z"
      fill="black"
    />
    <path
      d="M58.2424 86.0386C55.513 87.6144 52.0281 86.688 50.4585 83.9695C48.889 81.251 49.8293 77.7698 52.5587 76.194C55.288 74.6182 58.773 75.5445 60.3425 78.263C61.912 80.9815 60.9718 84.4628 58.2424 86.0386Z"
      fill="black"
    />
    <path
      d="M8.62575 42.4913C5.89636 44.0671 2.41141 43.1408 0.84188 40.4223C-0.727647 37.7038 0.212603 34.2226 2.94199 32.6467C5.67138 31.0709 9.15634 31.9973 10.7259 34.7158C12.2954 37.4343 11.3551 40.9155 8.62575 42.4913Z"
      fill="black"
    />
    <path
      d="M19.1813 60.7741C16.4519 62.3499 12.967 61.4235 11.3974 58.705C9.82791 55.9865 10.7682 52.5053 13.4975 50.9295C16.2269 49.3537 19.7119 50.28 21.2814 52.9985C22.8509 55.717 21.9107 59.1982 19.1813 60.7741Z"
      fill="black"
    />
    <path
      d="M29.7369 79.0568C27.0075 80.6326 23.5225 79.7063 21.953 76.9878C20.3835 74.2693 21.3237 70.7881 24.0531 69.2123C26.7825 67.6364 30.2674 68.5628 31.837 71.2813C33.4065 73.9998 32.4663 77.481 29.7369 79.0568Z"
      fill="black"
    />
    <path
      d="M39.8864 96.6364C37.157 98.2122 33.6721 97.2859 32.1026 94.5674C30.533 91.8489 31.4733 88.3676 34.2027 86.7918C36.9321 85.216 40.417 86.1424 41.9865 88.8609C43.5561 91.5794 42.6158 95.0606 39.8864 96.6364Z"
      fill="black"
    />
  </svg>
);
