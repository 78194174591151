import React, { FC, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import * as Styled from './landing-page.styled';
import CollabMainImg from './images/p0collab-main.png';
import CollabLogin from './images/p1collab-login.png';
import CollabFriends from './images/p2collab-friends.png';
import CollabInvite from './images/p3collab-invite.png';
import { FONTS, SPACES, WindowState } from '../theme';
import { DotIcon } from './icons/dot-icon.component';
import { BoldSquiggleIcon } from './icons/bold-squiggle-icon.components';
import { IMousePosition } from '../common/types/input.types';
import { usePerspective } from '../common/hooks/use-perspective.hooks';

interface IProps {
  mousePosition: IMousePosition;
  windowState: WindowState;
}

export const CollaborationContainer: FC<IProps> = ({ mousePosition, windowState }) => {
  const collabDotContainerRef = useRef<any>(null);
  const collabDotInnerRef = useRef<any>(null);
  usePerspective(collabDotContainerRef, collabDotInnerRef, 3, 2);
  const loginOffsetX = windowState === "md" ? 400 : windowState === "lg" ? 425 : 515;
  const loginOffsetY = windowState === "md" ? -20 : 0;
  const friendsOffsetX = windowState === "md" ? -350 :  windowState === "lg" ? -410 : -500;
  const friendsOffsetY = 95;
  const inviteOffsetX = windowState === "md" ? 340 : windowState === "lg" ? 400 : 480;
  const inviteOffsetY = windowState === "md" ? 210 : windowState === "lg" ? 260 : 335;
  return (
    <Styled.CollaborationContainer>
      <Box
        height={{ md: "100%", sm: "1px", xs: "1px" }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
      >
        <Styled.CollabMainContainer>
          <img height="535px" src={CollabMainImg} alt="collabMain" />
        </Styled.CollabMainContainer>
        <Styled.CollabLoginContainer
          mouseX={mousePosition.x}
          mouseY={mousePosition.y}
          windowInnerWidth={window.innerWidth}
          windowInnerHeight={window.innerHeight}
          parallaxEffect={-7}
          positionY={loginOffsetY}
          positionX={loginOffsetX}
          zIndex={2}
        >
          <img height='246px' src={CollabLogin} alt="collabLogin" />
        </Styled.CollabLoginContainer>
        <Styled.CollabFriendsContainer
          mouseX={mousePosition.x}
          mouseY={mousePosition.y}
          windowInnerWidth={window.innerWidth}
          windowInnerHeight={window.innerHeight}
          parallaxEffect={-7}
          positionX={friendsOffsetX}
          positionY={friendsOffsetY}
          zIndex={3}
        >
          <img height="189px" src={CollabFriends} alt="collabFriends" />
        </Styled.CollabFriendsContainer>
        <Styled.CollabInviteContainer
          mouseX={mousePosition.x}
          mouseY={mousePosition.y}
          windowInnerWidth={window.innerWidth}
          windowInnerHeight={window.innerHeight}
          parallaxEffect={-7}
          positionX={inviteOffsetX}
          positionY={inviteOffsetY}
          zIndex={2}
        >
          <img height="38px" src={CollabInvite} alt="collabInvite" />
        </Styled.CollabInviteContainer>
        <Styled.CollabDotContainer zIndex={2} ref={collabDotContainerRef}>
          <div ref={collabDotInnerRef}>
            <DotIcon />
          </div>
        </Styled.CollabDotContainer>
      </Box>
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        position="relative"
        marginTop={{ lg: 0, md: 0, sm: 30, xs: 30 }}
      >
        <Typography 
          fontSize={FONTS.SIZES.xxl} 
          fontFamily={FONTS.FAMILIES.inter}
          marginTop={{lg: SPACES.l, md: SPACES.l, sm: 0, xs: 0}} 
          variant="subtitle1"
          textAlign="center"
          zIndex={1}
          padding={{ md: 0, sm: `0 ${SPACES.l}`, xs: `0 ${SPACES.l}` }}
        >
          Experience effortless collaboration and a same-room feeling with your team.
        </Typography>
        <Styled.BoldSquiggleContainer zIndex={0}>
          <BoldSquiggleIcon />
        </Styled.BoldSquiggleContainer>
      </Box>
    </Styled.CollaborationContainer>
  );
};
