export const COLORS = {
  primary: '#141414',
  secondary: '#141414',
  white: '#ffffff',
  black: '#000000',
  grey: '#e9e9e9',
  green: '#00A40B',
  blue: '#0157FC',
  darkGrey: '#313131',
  red: '#c61825',
  orange: '#fed101',
  darkOrange: '#ef7300',
  purple: '#E40040',
  discord: '#7289da',
  semiTransparentWhite: 'rgba(0, 0, 0, 0.1)',
  halfTransparentGrey: 'rgba(145, 143, 145, 0.5)',
  darkBlue: '#102b6a',
  lightGrey: '#f5f5f5',
  darkerGrey: '#8e8e8e',
  violetBlue: '#4344ce',
  skyBlue: '#e3f4fb',
  lightSkyBlue: '#eaf1f6',
  lightGreen: '#d0ffdb',

  transparent: 'transparent',
  lightBlue: '#A0DBF2',
  lightPurple: '#E1C3FF',
  semiTransparentBlue: 'rgba(234, 241, 246, 0.8)',
  lightTransparentBlue: 'rgba(107, 179, 242, 0.3)',
  semiTransparentGrey: 'rgba(234, 241, 246, 0.5)',
  online: '#65D072',
  onlineBorder: '#43B055',
  busy: '#FCF05C',
  busyBorder: '#ECE14D',
  offline: '#B0B0B0',
  offlineBorder: '#868686',
  mostLightBlue: '#D9EEF7',
  dividerGrey: 'rgba(176, 176, 176, 0.3)'
};
