import React from 'react';

export const StartIcon = () => (
  <svg
    width="406"
    height="535"
    viewBox="0 0 406 535"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M254.5 0L286.074 170.325L411.733 51.088L337.162 207.443L508.908 184.838L356.676 267.5L508.908 350.162L337.162 327.557L411.733 483.912L286.074 364.675L254.5 535L222.926 364.675L97.2674 483.912L171.838 327.557L0.0923767 350.162L152.324 267.5L0.0923767 184.838L171.838 207.443L97.2674 51.088L222.926 170.325L254.5 0Z"
      fill="#D0FFDB"
    />
  </svg>
);
