import { useEffect } from "react";

export const usePerspective = (
  containerRef: any, 
  innerRef: any, 
  perspective: number = 30, 
  rotateMultiplier: number = 1
) => {
  useEffect(() => {
    if (!containerRef.current || !innerRef.current) return;

    const container = containerRef.current;
    const inner = innerRef.current;

    container.style.perspective = `${perspective}px`;
    inner.style.transition = 'transform 0.5s';

    const mouse = {
      x: 0,
      y: 0,
      setOrigin: function (e: HTMLElement) {
        const rect = e.getBoundingClientRect();
        this.x = Math.floor(rect.left + rect.width / 2);
        this.y = Math.floor(rect.top + rect.height / 2);
      },
    };
    mouse.setOrigin(container);

    let counter = 0;
    const refreshRate = 10;
    const isTimeToUpdate = function () {
      return counter++ % refreshRate === 0;
    };

    const onMouseEnterHandler = function (event: MouseEvent) {
      update(event);
    };
    const onMouseLeaveHandler = function () {
      inner.style.transform = "";
    };
    const onMouseMoveHandler = function (event: MouseEvent) {
      if (isTimeToUpdate()) {
        update(event);
      }
    };

    const update = function (event: MouseEvent) {
      const rect = container.getBoundingClientRect();
      mouse.x = event.clientX - rect.left;
      mouse.y = event.clientY - rect.top;
      updateTransformStyle(
        (mouse.y / rect.height - 0.5).toFixed(2),
        (mouse.x / rect.width - 0.5).toFixed(2)
      );
    };

    const updateTransformStyle = function (x: string, y: string) {
      const style = `rotateX(${+x * rotateMultiplier * -1}deg) rotateY(${+y * rotateMultiplier}deg)`;
      inner.style.transform = style;
    };

    container?.addEventListener("mousemove", onMouseMoveHandler);
    container?.addEventListener("mouseleave", onMouseLeaveHandler);
    container?.addEventListener("mouseenter", onMouseEnterHandler);
    return () => {
      container?.removeEventListener("mousemove", onMouseMoveHandler);
      container?.removeEventListener("mouseleave", onMouseLeaveHandler);
      container?.removeEventListener("mouseenter", onMouseEnterHandler);
    };
  }, [containerRef, innerRef, perspective, rotateMultiplier]);
}
