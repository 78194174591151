import React, { FC, useEffect, useState } from 'react';
import { ICardCarousel } from './card-carousel.type';
import { DotIcon } from '../../../landing-page/icons/dot-icon.component';
import * as Styled from './card-carousel.styled';
import { COLORS } from '../../../theme';
import TextTransition from 'react-text-transition';

interface IProps {
  cards: ICardCarousel[];
  interval: number;
  maxCardHeight?: number;
}

export const CardCarousel: FC<IProps> = React.memo(({ cards, interval, maxCardHeight }) => {
  const [activeIndex, setActivetndex] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timer>();
  const nextCard = () => {
    const timerLocal = setInterval(() => {
      setActivetndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, interval);
    setTimer(timerLocal);
    return () => {
      clearInterval(timerLocal);
    };
  };
  const onDotClick = (index: number) => {
    if (timer) clearInterval(timer);
    setActivetndex(index);
    nextCard();
  };
  useEffect(() => nextCard(), [cards.length, interval]);
  if (cards.length === 0) return <div />;
  return (
    <Styled.PaginationWrapper>
      <Styled.PaginationContainer>
        {cards.map((card, index) => {
          const isSelected = index === activeIndex;
          const radius = isSelected ? 8 : 6;
          const color = isSelected ? COLORS.black : COLORS.darkerGrey;
          return (
            <Styled.DotWrapper key={index} onClick={() => !isSelected && onDotClick(index)} role="presentation">
              <DotIcon radius={radius} color={color} />
            </Styled.DotWrapper>
          );
        })}
      </Styled.PaginationContainer>
      <Styled.CardsContainer maxHeight={maxCardHeight}>
        <TextTransition inline direction="down">
          <img src={cards[activeIndex].imgSrc} alt='CarouselCard' />
        </TextTransition>
      </Styled.CardsContainer>
    </Styled.PaginationWrapper>
  );
});
