import React from 'react';

export const BoldSquiggleIcon = () => (
  <svg
    width="427"
    height="252"
    viewBox="0 0 427 252"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M21 110.246L51.5304 144.572C92.6653 190.821 167.645 180.165 194.267 124.288L215.985 78.7013C247.934 11.6426 342.719 9.81523 377.228 75.5927L412.946 143.673C432.231 180.431 487.306 170.922 493.148 129.825V129.825C501.394 71.8204 581.244 62.4297 602.722 116.939L652 242"
      stroke="#E1C3FF"
      strokeWidth="54"
    />
  </svg>
);
