import React from 'react';

export const SmallHalfEllipseIcon = () => (
  <svg
    width="267"
    height="134"
    viewBox="0 0 267 134"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M266.209 0.626945C266.209 18.0927 262.769 35.3875 256.085 51.5238C249.401 67.6601 239.604 82.3219 227.254 94.6721C214.904 107.022 200.242 116.819 184.106 123.503C167.97 130.187 150.675 133.627 133.209 133.627C115.743 133.627 98.4484 130.187 82.3121 123.503C66.1758 116.819 51.514 107.022 39.1638 94.6721C26.8136 82.3219 17.0169 67.6601 10.333 51.5238C3.64913 35.3875 0.208983 18.0927 0.208984 0.62693L66.709 0.626945C66.709 9.35984 68.4291 18.0072 71.771 26.0754C75.1129 34.1435 80.0113 41.4744 86.1864 47.6495C92.3615 53.8246 99.6924 58.723 107.761 62.0649C115.829 65.4069 124.476 67.1269 133.209 67.1269C141.942 67.1269 150.589 65.4069 158.657 62.0649C166.726 58.723 174.056 53.8246 180.232 47.6495C186.407 41.4744 191.305 34.1435 194.647 26.0754C197.989 18.0072 199.709 9.35985 199.709 0.626945H266.209Z"
      fill="#E1C3FF"
    />
  </svg>
);
