import React from 'react';
import * as Styled from './landing-page.styled';
import { NearLogoIcon } from '../common/components/icons/near-logo-icon.component';

export const NavbarComponent = () => (
  <Styled.NavbarWrapper>
    <Styled.NavbarContainer maxWidth="xl">
      <NearLogoIcon />
      <Styled.NavbarButton 
        target="_blank" 
        href={process.env.REACT_APP_REQUEST_ACCESS_URL} 
      >
        Request Access
      </Styled.NavbarButton>
    </Styled.NavbarContainer>
  </Styled.NavbarWrapper>
);
