import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  gap: 10px;
  z-index: 1;
  align-items: center;
`;

export const PaginationContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;

export const DotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

interface IImageContainerProps {
  maxHeight?: number;
}

export const CardsContainer = styled.div<IImageContainerProps>`
  position: relative;
  height: ${(props) => props.maxHeight ? `${props.maxHeight + 20}px` : 'auto'};
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  .text-transition {
    position: relative;
    overflow: hidden;
    width: 100% !important;
    height: 100% !important;
  }
  .text-transition div {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    img {
      max-height: ${(props) => props.maxHeight ? `${props.maxHeight}px` : 'auto'};
    }
  }
`;
