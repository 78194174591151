import { ICardCarousel } from '../common/components/card-carousel/card-carousel.type';
import Card1 from './images/p1card.png';
import Card2 from './images/p2card.png';
import Card3 from './images/p3card.png';

import { IBenefitsBlock } from './landing-page.types';
import { TimeManagementIcon } from './icons/time-management-icon.component';
import { NetworkingIcon } from './icons/networking-icon.component';
import { ChatIcon } from './icons/chat-icon.component';
import { VoiceMessageIcon } from './icons/voice-message-icon.component';
import { HandshakeIcon } from './icons/handshake-icon.component';

export const headerWelcomeNames = [
  'Jackson',
  'Sophia',
  'Maria',
  'José',
  'Ava',
  'Ethan',
  'Fatima',
  'Ji-Yeon',
  'Ahmed',
  'Giovanni'
];

export const lpCarouselCards: ICardCarousel[] = [
  { imgSrc: Card1 },
  { imgSrc: Card2 },
  { imgSrc: Card3 }
];

export const benefitsBlocks: IBenefitsBlock[] = [
  {
    blockSize: 4,
    imgSrc: TimeManagementIcon,
    number: 1,
    subTitle: 'Improved efficiency',
    subText:
      'Reduce time wasted on setting up calls and dealing with connection issues, making your team more productive.'
  },
  {
    blockSize: 4,
    imgSrc: NetworkingIcon,
    number: 2,
    subTitle: 'Better team cooperation',
    subText: 'Enhance collaboration and teamwork by creating a same-room feeling for remote teams.'
  },
  {
    blockSize: 4,
    imgSrc: ChatIcon,
    number: 3,
    subTitle: 'Effortless communication',
    subText:
      'Communicate with your team as naturally and effortlessly as speaking, thanks to Near’s advanced speech recognition.'
  },
  {
    blockSize: 6,
    imgSrc: VoiceMessageIcon,
    number: 4,
    subTitle: 'Enhanced audio quality',
    subText:
      'Enjoy superior call quality with Near’s   state-of-the-art VoIP technology, making conversations smoother and more enjoyable.'
  },
  {
    blockSize: 6,
    imgSrc: HandshakeIcon,
    number: 5,
    subTitle: 'Increased flexibility',
    subText:
      'Near allows you to stay connected with your team, friends, and family, no matter where you are in the world.'
  }
];
