/* eslint-disable max-len */
import React from 'react';

export const NearLogoIcon = () => (
  <svg width="87" height="20" viewBox="0 0 87 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 18.6143V0.439707H4.14058L4.507 3.51766C5.06885 2.44282 5.87498 1.58783 6.92539 0.952699C8.00023 0.317566 9.25828 0 10.6995 0C12.9469 0 14.6936 0.708417 15.9394 2.12525C17.1852 3.54209 17.8081 5.61848 17.8081 8.35444V18.6143H13.1179V8.79415C13.1179 7.23074 12.8004 6.03376 12.1652 5.2032C11.5301 4.37264 10.5408 3.95737 9.19721 3.95737C7.87809 3.95737 6.79104 4.4215 5.93605 5.34977C5.10549 6.27804 4.69021 7.57274 4.69021 9.23385V18.6143H0Z"
      fill="black"
    />
    <path
      d="M31.1239 19.054C29.2918 19.054 27.6673 18.6631 26.2505 17.8814C24.8336 17.0997 23.7222 16.0005 22.916 14.5836C22.1099 13.1668 21.7068 11.5301 21.7068 9.67356C21.7068 7.79259 22.0977 6.11926 22.8794 4.65357C23.6855 3.18788 24.7848 2.05197 26.1772 1.24584C27.594 0.415279 29.2551 0 31.1605 0C32.9438 0 34.5194 0.390851 35.8874 1.17255C37.2554 1.95425 38.318 3.02909 39.0753 4.39707C39.857 5.74062 40.2478 7.24296 40.2478 8.90407C40.2478 9.17278 40.2356 9.45371 40.2112 9.74684C40.2112 10.04 40.199 10.3453 40.1745 10.6629H26.3604C26.4581 12.0797 26.9467 13.1912 27.8261 13.9973C28.7299 14.8035 29.817 15.2065 31.0872 15.2065C32.0399 15.2065 32.8339 14.9989 33.469 14.5836C34.1286 14.1439 34.6171 13.5821 34.9347 12.8981H39.6982C39.3562 14.0462 38.7821 15.0966 37.976 16.0493C37.1943 16.9776 36.2172 17.7104 35.0446 18.2479C33.8965 18.7853 32.5896 19.054 31.1239 19.054ZM31.1605 3.8108C30.0124 3.8108 28.9986 4.14058 28.1192 4.80014C27.2398 5.43527 26.678 6.4124 26.4337 7.73152H35.4843C35.411 6.53454 34.9713 5.58184 34.1652 4.87342C33.3591 4.165 32.3575 3.8108 31.1605 3.8108Z"
      fill="black"
    />
    <path
      d="M51.631 19.054C49.921 19.054 48.3942 18.6387 47.0507 17.8081C45.7071 16.9776 44.6445 15.8417 43.8628 14.4004C43.0811 12.9592 42.6903 11.3225 42.6903 9.49035C42.6903 7.65823 43.0811 6.03376 43.8628 4.61693C44.6445 3.17566 45.7071 2.05197 47.0507 1.24584C48.3942 0.415279 49.921 0 51.631 0C52.9989 0 54.1959 0.256496 55.2219 0.769488C56.2479 1.28248 57.0785 2.00311 57.7136 2.93138L57.9701 0.439707H62.4038V18.6143H58.0067L57.7136 16.0127C57.1273 16.8188 56.3456 17.5272 55.3685 18.1379C54.4158 18.7486 53.1699 19.054 51.631 19.054ZM52.6203 14.95C54.1349 14.95 55.3685 14.4493 56.3212 13.4477C57.2983 12.4217 57.7869 11.1148 57.7869 9.52699C57.7869 7.93916 57.2983 6.64446 56.3212 5.64291C55.3685 4.61693 54.1349 4.10393 52.6203 4.10393C51.1302 4.10393 49.8966 4.60471 48.9194 5.60627C47.9423 6.60782 47.4537 7.90252 47.4537 9.49035C47.4537 11.0782 47.9423 12.3851 48.9194 13.4111C49.8966 14.4371 51.1302 14.95 52.6203 14.95Z"
      fill="black"
    />
    <path
      d="M67.9127 18.6143V0.439707H72.09L72.5297 3.84744C73.1892 2.67489 74.0808 1.74661 75.2045 1.06263C76.3527 0.354208 77.6962 0 79.2352 0V4.94671H77.9161C76.8901 4.94671 75.974 5.10549 75.1679 5.42306C74.3618 5.74062 73.7266 6.29026 73.2625 7.07196C72.8228 7.85366 72.6029 8.94071 72.6029 10.3331V18.6143H67.9127Z"
      fill="black"
    />
    <path
      d="M83.7219 18.8708C82.8669 18.8708 82.1585 18.6021 81.5966 18.0646C81.0592 17.5272 80.7905 16.8799 80.7905 16.1226C80.7905 15.3409 81.0592 14.6813 81.5966 14.1439C82.1585 13.6065 82.8669 13.3378 83.7219 13.3378C84.5769 13.3378 85.2731 13.6065 85.8105 14.1439C86.3723 14.6813 86.6533 15.3409 86.6533 16.1226C86.6533 16.8799 86.3723 17.5272 85.8105 18.0646C85.2731 18.6021 84.5769 18.8708 83.7219 18.8708Z"
      fill="#0583F2"
    />
  </svg>
);
