import { LandingPage } from '../landing-page';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import * as theme from '../theme';
import { muiTheme } from '../theme';
import * as Styled from './app.styled';
import { Container } from '@mui/material';
import '../../style.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <Styled.GlobalStyles />
        <Container disableGutters maxWidth={false}>
          <LandingPage />
        </Container>
      </MuiThemeProvider>
    </ThemeProvider>
  )
}

export default App;
